import { useEffect } from 'react';
import { 
  Datagrid,  
  List,  
  TextField,
  TextInput,
  useRecordContext
   } from 'react-admin';

export const ExternalShortList = () => {
  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('List Own Shorts', {props: {user: userEmail}})
    }
  },[])

  return (
  <List filters={getFilters()}>
      <Datagrid bulkActionButtons={false}>
        <Thumbnail/>
        <Link source="title" label = 'Title'/>
        <TextField source='original_title' />
        <TextField source='publish_date' label = 'Published' />
        <TextField source="views" />
        <TextField source="views_last_2h" />
        <TextField source="views_last_24h" label='Views last 24h' />
        <TextField source="views_first_24h" label='Views first 24h' />
        <TextField source="views_first_week" label='Views first week' />
        <TextField source="duration" />
      </Datagrid>
  </List>
);
}

const getFilters = () => {
return [
  <TextInput source="searchPhrase" label="Search" alwaysOn />,
]
}

const Thumbnail = () =>{
  const record = useRecordContext()
  if(!record) return null;
  let thumbnailHighRes = record['thumbnail_url'].replace('default','maxresdefault')
  return (
    <a href={thumbnailHighRes} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail_url']} alt ={record['title']} style = {{height:'6em'}}/>
    </a>
)
}
const Link = ({source}) =>{
  const record = useRecordContext()
  return record ? (
      <a href={'https://www.youtube.com/watch?v='+record['video_id']} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}