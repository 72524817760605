import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getExternalShorts = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        searchPhrase: params.filter.searchPhrase || '',
        projectId:1 // hardcoded project id for the moment to only show Kids Channels
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.externalShortsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data = rawData.data.map(short=>{
        return {
            ...short,
            publish_date: short.publish_date?.substring(0,short.publish_date.lastIndexOf(':')).replace("T"," ").replace("00:00",""),
            views: Number.parseInt(short.views || 0).toLocaleString(),
            views_last_2h: (short.views_last_2h || 0).toLocaleString(),
            views_last_24h: (short.views_last_24h || 0).toLocaleString(),
            views_first_24h: (short.views_first_24h || 0).toLocaleString(),
            views_first_week: (short.views_first_week || 0).toLocaleString()
        }
    })
    return {data:data,total:data.length}
        
}